import React from "react";
import { useTranslation } from "react-i18next";

import {
    PageWrapper,
    StyledTypingEffect,
    DescriptionWrapper,
    Description,
    VerticalDivider,
    HorizontalDivider,
    Wrapper,
    Title,
} from "./style";
import Breakpoint from "../../../../../common/Breakpoint";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";
interface WhoWeAreProps {
    pageChange: PageChange;
}

const WhoWeAre: React.FC<WhoWeAreProps> = ({ pageChange }) => {
    const { t } = useTranslation("main");
    const descriptions = (t(`whoWeAre`) as unknown) as string[];
    const nOfDescriptions = descriptions.length - 1;

    return (
        <>
            <PageTitle subtitle={t(`whoWeAre_title`)} />
            <PageWrapper animation={pageChange}>
                    <Title>{t(`whoWeAre_title`)}</Title>
                    <Wrapper>
                        {descriptions.map((description, index) => (
                            <React.Fragment key={index}>
                                <Breakpoint device="tablet">
                                    <p>{fixConjunctions(description)}</p>
                                    {index !== nOfDescriptions && <HorizontalDivider />}
                                </Breakpoint>
                                <Breakpoint device="tabletLandscape">
                                    <p>{fixConjunctions(description)}</p>
                                    {index !== nOfDescriptions && <VerticalDivider />}
                                </Breakpoint>
                                <Breakpoint device="computer">
                                    <p>{fixConjunctions(description)}</p>
                                    {index !== nOfDescriptions && <VerticalDivider />}
                                </Breakpoint>
                            </React.Fragment>
                        ))}
                    </Wrapper>
            </PageWrapper>
        </>
    );
};

export default WhoWeAre;
